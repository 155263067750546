<template>
  <div id="our-proposals" :style="{height: height + 'px'}">
    <b-col cols="12" sm="6" md="6" lg="6" class="container-our-proposal">
     <div class="container-info-about">
       <div class="sub-title">PRODOTTI</div>
       <div class="title-about">Le Nostre Proposte</div>
       <div class="border-title"></div>
       <div class="description">Consulenza ed esperienza al servizio del cliente per seguirlo e consigliarlo prima e dopo l’acquisto.</div>
     </div>
    </b-col>
    <b-col cols="0" sm="6" md="6" lg="6" class="container-img-about-us" :style="{height: height + 'px'}">
    </b-col>
  </div>
</template>

<script>
export default {
  name: "OurProposals",
  components: {},
  props: {
    height: {
      type: Number
    },
    width: {
      type: Number
    }
  },
  methods: {
    getImage: function (icon) {
      return require('../../assets/img/'+icon + '.svg')
    }
  }
}
</script>

<style lang="less">
@import '../../assets/styles/main.less';
@import '../../assets/styles/font.less';

#our-proposals {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .container-info-about {
    text-align: start;
    padding-left: 50%;
    .sub-title {
      .set-font-bold(@color: @subtitle; @size: 15px);
    }
    .title-about {
      height: 130px;
      .set-font-bold(@color: @myBlack; @size: 50px);
    }
    .border-title {
      border: 2px solid @borderTitle;
      width: 30%;
      margin-bottom: 20px;
    }
    .description {
      .set-font(@color: @myBlack; @size: 18px);
    }
  }

  .container-img-about-us {
  }
}

@media only screen and (max-width: 1500px) {
  #our-proposals {
    .container-info-about {
      margin-top: 160px;
    }
  }
}

@media only screen and (max-width: 900px) {
  #our-proposals {
    height: 1300px !important;
    .container-info-about {
      text-align: start;
      padding-left: 0%;
      margin-top: 180px;
      .sub-title {
        padding-top: 20px;
        .set-font-bold(@color: @subtitle; @size: 15px);
      }

      .title-about {
        height: 130px;
        .set-font-bold(@color: @myBlack; @size: 50px);
      }

      .border-title {
        border: none;
      }

      .description {
        padding-bottom: 20px;
        .set-font(@color: @myBlack; @size: 15px);
      }
    }
  }
}
</style>
