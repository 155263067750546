<template>
  <div class="layout-home">
    <HomeView :width="width" :height="height - 100"></HomeView>
    <section-sale :height="height - 100"></section-sale>
    <about-us :height="height - 100"></about-us>
    <why-choose-us :height="height"></why-choose-us>
    <banner-rental></banner-rental>
    <our-proposals :height="height"></our-proposals>
  </div>
</template>

<script>
import HomeView from "@/components/home/HomeView.vue";
import SectionSale from "@/components/home/SectionSale.vue";
import AboutUs from "@/components/home/AboutUs.vue";
import WhyChooseUs from "@/components/home/WhyChooseUs.vue";
import OurProposals from "@/components/home/OurProposals.vue";
import BannerRental from "@/components/home/BannerRental.vue";

export default {
  name: "HomeLayout",
  props: {
    height: {
      type: Number
    },
    width: {
      type: Number
    }
  },
  components: {BannerRental, OurProposals, WhyChooseUs, AboutUs, SectionSale, HomeView}
}
</script>

<style lang="less">
@import '../assets/styles/main.less';
@import '../assets/styles/font.less';

.layout-home{
  background-color: @myWhite;
}
</style>
