<template>
  <b-container class="page">
    <b-row>

      <b-col class="container-box-elements" cols="12" md="6">
        <div class="main-title">
          <div class="main-title-2"></div>
          <div class="main-title-3">
            Laboratorio
          </div>
        </div>
        <div class="quote">
          <p>l'esperienza maturata dal 1986 ad oggi ci permette di consigliare la sitemazione dell'attrezzatura
            (sci/snowboard) prima dell'inizio della stagione invernale, per massimizzare la sicurezza ed il divertimento
            sullle piste.<br>

            con la nuova generazione di macchinari viene rivoluzionata la riparazione della soletta, grazie alle
            spianature e rettifica con limatura a pietra.<br>

            l'esclusiva tecnologia CERAMIC DISC FINISCH garantisce precisione geometrica dell'angolazione delle lamine
            grazie alle mole a disco in ceramica, permettendo un asportazione uniforme per tutta la lunghezza dello sci.<br>

            il processo di ripristino e manutenzione dell'attrezzo passa poi per l'impronta, sempre attuata tramite
            pietra, per poi passare alla lucidatura della soletta e delle lamine, per poi concludersi con la
            sciolinatura.</p>
        </div>
      </b-col>

      <b-col class="container-box-elements" cols="12" md="6">
        <simple-svg
            :src="getImage('laboratorio')"
            width="100%"
            height="100%"
            custom-class-name="icon-gray"
        />
      </b-col>
    </b-row>

    <br>
    <div class="content-left">
      <h2>Servizi</h2>
      <h1>AFFILATURA PATTINI DA GHIACCIO (ARTISTICO-HOCKEY) </h1>
      <p>Con il passare del tempo il filo dei pattini si deteriora inesorabilmente.<br>
        Per ovviare questa problematica consigliamo l'affilatura almeno una volta l'anno. Questo consente di rendere il tuo pattino stabile e preciso, garantendone la tenuta sul ghiaccio.<br>
        Solo da noi, la procedura viene effettuata in pochi minuti, con strumenti di ultima generazione.
      </p>
    </div>

    <div class="content-right">
      <h2>Servizi</h2>
      <h1>INCORDATURA RACCHETTE DA TENNIS</h1>
      <p>Incordare regolarmente la propria racchetta da tennis è una pratica che crea diversi vantaggi.<br>
        Prima di tutto, aiuta la prevenzione dagli infortuni, prolunga la vita della propria racchetta fino ad influire nettamente sul miglioramento delle prestazioni di gioco.<br>
        Affidati al nostro staff per una valutazione sulla corretta modalità di incordatura che varia a seconda della tipologia di giocatore.
      </p>
    </div>

    <div class="content-left">
      <h2>Servizi</h2>
      <h1>TERMOREGOLAZIONE SCARPONE DA SCI</h1>
      <p>Lo scarpone è forse l'attrezzo più importante per lo sciatore, tramite esso infatti, le gambe e le caviglie trasmettono i comandi agli sci. Quelli di ultima generazione sono sicuramente più confortevoli, sia per quanto riguarda il peso, sia per le mescole che compongono lo scarpone stesso. delle volte però non è sufficiente nel creare il giusto grado di fit che l'utilizzatore vuole raggiungere.<br>
        Proprio per questo motivo da noi, tramite la pratica della termoformazione, puoi rendere lo scarpone confortevole, comodo ed anatomicamente modellato sul piede dell'utilizzatore.
      </p>
    </div>

  </b-container>
</template>

<script>
export default {
  name: "laboratorio-page",
  methods: {
    getImage: function (icon) {
      return require('../assets/img/' + icon + '.svg')
    }
  }
}
</script>

<style lang="less">
@import '@/assets/styles/main.less';
@import '@/assets/styles/font.less';

#detail-page {
  margin: 10%;
}
</style>
