<template>
  <div id="section-sale">
    <b-col class="container-box-elements" cols="12" md="4" lg="4"
           :style="{'backgroundColor': elements[0].background, 'color': elements[0].color}"
           @click="goTo('negozio')">
      <div style="opacity:30%">
        <simple-svg
            :src="getImage(elements[0].icon)"
            width="200"
            height="200"
            custom-class-name="background-icon"
        />
      </div>
      <div class="container-single-element">
        <div class="container-icon single-element">
          <simple-svg
              :src="getImage(elements[0].icon)"
              width="100%"
              height="100%"
              custom-class-name="icon-white"
          />
        </div>
        <div class="container-title single-element">
          <span class="title-element" :style="{'color': elements[0].color}">{{ elements[0].title }}</span>
        </div>
        <div class="container-description single-element">
          <span class="description-element">{{ elements[0].description }}</span>
        </div>
      </div>
    </b-col>

    <b-col class="container-box-elements center-element" cols="12" md="4" lg="4"
           :style="{'backgroundColor': elements[1].background}"
           @click="goTo('noleggio')">
      <div style="opacity:100%">
        <simple-svg
            :src="getImage(elements[1].icon)"
            width="200"
            height="200"
            custom-class-name="background-icon"
        />
      </div>
      <div class="container-single-element">
        <div class="container-icon single-element">
          <simple-svg
              :src="getImage(elements[1].icon)"
              width="100%"
              height="100%"
          />
        </div>
        <div class="container-title single-element">
          <span class="title-element">{{ elements[1].title }}</span>
        </div>
        <div class="container-description single-element">
          <span class="description-element">{{ elements[1].description }}</span>
        </div>
      </div>
    </b-col>

    <b-col class="container-box-elements" cols="12" md="4" lg="4"
           :style="{'backgroundColor': elements[2].background, 'color': elements[2].color}"
           @click="goTo('laboratorio')">
      <div style="opacity:30%">
        <simple-svg
            :src="getImage(elements[2].icon)"
            width="200"
            height="200"
            opacity="30%"
            custom-class-name="background-icon"
        />
      </div>
      <div class="container-single-element">
        <div class="container-icon single-element">
          <simple-svg
              :src="getImage(elements[2].icon)"
              width="100%"
              height="100%"
              custom-class-name="icon-white"
          />
        </div>
        <div class="container-title single-element">
          <span class="title-element" :style="{'color': elements[0].color}">{{ elements[2].title }}</span>
        </div>
        <div class="container-description single-element">
          <span class="description-element">{{ elements[2].description }}</span>
        </div>
      </div>
    </b-col>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "SectionSale",
  props: {
    height: {
      type: Number
    },
    width: {
      type: Number
    }
  },
  data() {
    return {
      elements: [
        {
          title: 'NEGOZIO',
          icon: 'negozio',
          link: 'negozio',
          background: '#111111',
          color: '#FFFFFF',
          description: 'Vasta gamma di prodotti per lo sci, snowboard, pattinaggio, trekking e tanto altro.'
        },
        {
          title: 'NOLEGGIO',
          icon: 'noleggio',
          link: 'noleggio',
          background: '#EEEEEE',
          color: '',
          description: 'La migliore attrezzatura da sci/snowboard, giornalmente o per l’intera stagione.'
        },
        {
          title: 'LABORATORIO',
          icon: 'laboratorio',
          link: 'laboratorio',
          background: '#39BCEA',
          color: '#FFFFFF',
          description: 'Competenza e professionalità al tuo servizio per la riparazione di sci e snowboard.'
        },
      ]
    }
  },
  computed: {
    svgFillColor() {
      return 'tomato'
    },
  },
  methods: {
    getImage: function (icon) {
      return require('../../assets/img/' + icon + '.svg')
    },
    goTo: function (link) {
      router.push({name: link})
      window.scrollTo(0, 0)
    }
  }
}
</script>

<style lang="less">
@import '../../assets/styles/main.less';
@import '../../assets/styles/font.less';

#section-sale {
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  // padding: 0px 300px;

  .container-box-elements {
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;

    .background-icon {
      position: absolute;
      bottom: 0px;
      right: 0px;
      fill: @myGrayLight;
      stroke: @myGrayLight;
    }

    &.center-element {
      .container-single-element {
        padding: 0px 150px;

        .single-element {
          .title-element {
            .set-font-bold(@size: 20px; @color: @myRed);
          }

          .description-element {
            .set-font(@color: @myBlack);
          }
        }
      }
    }

    .container-single-element {
      text-align: center;
      position: absolute;
      padding: 0px 50px;

      .container-icon {
        height: 100px;

        .icon-white {
          fill: @myWhite;
          stroke: @myWhite;
        }
      }

      .container-title {
        height: 50px;
      }

      .single-element {
        padding: 10px 0px;

        .img-element {
          height: 80px;
        }

        .title-element {
          .set-font-bold(@size: 20px)
        }
      }
    }
  }

  .container-box-elements:last-child {
    .background-icon {
      position: absolute;
      bottom: 0px;
      right: 200px;
    }
  }

  .container-box-elements:last-child {
    padding-right: 15%;
    padding-left: 50px;
  }

  .container-box-elements:first-child {
    padding-left: 15%;
    padding-right: 50px;
  }
}

@media only screen and (max-width: 1350px) {
  #section-sale {
    .container-box-elements {
      &.center-element {
        .container-single-element {
          padding: 0px 70px;
        }
      }
    }

    .container-box-elements:last-child {
      .background-icon {
        position: absolute;
        bottom: 0px;
        right: 0px;
      }
    }

    .container-box-elements:last-child {
      padding-right: 10%;
      padding-left: 50px;
    }

    .container-box-elements:first-child {
      padding-left: 10%;
      padding-right: 50px;
    }
  }
}

@media only screen and (max-width: 900px) {
  #section-sale {
    height: 1200px;
    justify-content: center;
    align-items: center;

    .container-box-elements:last-child {
      padding-right: 10px;
      padding-left: 10px;
    }

    .container-box-elements:first-child {
      padding-left: 10px;
      padding-right: 10px;
    }

    .container-box-elements {
      padding: 0px 10px;

      &.center-element {
        .container-single-element {
          padding: 0px 10px;
        }
      }
    }

    .container-box-elements:last-child {
      .background-icon {
        position: absolute;
        bottom: 0px;
        right: 0px;
      }
    }
  }
}
</style>
