<template>
  <div>
    <b-container class="page">
      <b-row>

        <b-col class="container-box-elements" cols="12" md="6">
          <div class="main-title">
            <div class="main-title-2"></div>
            <div class="main-title-3">
              Snowboard
            </div>
          </div>
          <div class="quote">
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
              dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
              ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
              fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit
              anim id est laborum.</p>
          </div>
        </b-col>

        <b-col class="container-box-elements" cols="12" md="6">
          <simple-svg
              :src="getImage('snow')"
              width="100%"
              height="100%"
              custom-class-name="icon-gray"
          />
        </b-col>
      </b-row>
    </b-container>

    <div class="page logo-container">
      <b-container>
        <div>
          <div class="title-logo">I nostri marchi</div>
          <div class="grid-container">
            <div class="logo">
              <img :src="getLogo('atomic.png')" alt="Logo">
            </div>
            <div class="logo">
              <img :src="getLogo('boing.png')" alt="Logo">
            </div>
            <div class="logo">
              <img :src="getLogo('cairn.webp')" alt="Logo">
            </div>
            <div class="logo">
              <img :src="getLogo('nasa.png')" alt="Logo">
            </div>
            <div class="logo">
              <img :src="getLogo('spacex.png')" alt="Logo">
            </div>
          </div>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "snowboard-page",
  methods: {
    getImage: function (icon) {
      return require('../assets/img/' + icon + '.svg')
    },
    getLogo: function (icon) {
      return require('../assets/img/' + icon)
    }
  }
}
</script>

<style lang="less">
@import '@/assets/styles/main.less';
@import '@/assets/styles/font.less';

.logo-container {
  background-color: #EEEEEE;
  padding-bottom: 150px;
}

.title-logo {
  padding-top: 10px;
  letter-spacing: 1px;
  .set-font-bold(@family: "avenir-black", @weight: normal, @size: 36px, @color: @myGray);
}

.grid-container {
  display: flex;
  flex-wrap: wrap; /* I loghi andranno a capo quando non c'è spazio */
  gap: 20px; /* Spazio tra le immagini */
}

.logo {
  width: 100px; /* Larghezza desiderata dei loghi */
  height: 100px; /* Altezza desiderata dei loghi */
}

.logo img {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Scala l'immagine mantenendo le proporzioni */
}

</style>
