import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeLayout from '../components/HomeLayout.vue'
import negozio from "@/components/Negozio.vue";
import noleggio from "@/components/Noleggio.vue";
import laboratorio from "@/components/Laboratorio.vue";

import sci from "@/components/Sci.vue";
import snowboard from "@/components/Snowboard.vue";
import pattini from "@/components/Pattini.vue";
import accessories from "@/components/Accessories.vue";

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeLayout
    },
    {
        path: '/negozio',
        name: 'negozio',
        component: negozio
    },
    {
        path: '/noleggio',
        name: 'noleggio',
        component: noleggio
    },
    {
        path: '/laboratorio',
        name: 'laboratorio',
        component: laboratorio
    },
    {
        path: '/sci',
        name: 'sci',
        component: sci
    },
    {
        path: '/snowboard',
        name: 'snowboard',
        component: snowboard
    },
    {
        path: '/pattini',
        name: 'pattini',
        component: pattini
    },
    {
        path: '/accessories',
        name: 'accessories',
        component: accessories
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
