<template>
  <div id="banner-rental">
    <b-col sm="3" md="3" lg="3" class="container-img-rental"></b-col>
    <b-col sm="9" md="9" lg="9" class="container-title">
      <div class="rental-title">IL NOLEGGIO CHE CONVIENE</div>
      <div class="border-title"></div>
      <div class="description">vivi lo sci al meglio con il nostro noleggio scegliendo la durata e la tipologia che più ti piace.<br>Tutti i nostri sci vengono consegnati sciolinati e laminati, gli scarponi ed i caschi lavati e disinfettati.</div>
      <b-button variant="outline-primary" class="btn-info-rental">READ MORE</b-button>
    </b-col>
  </div>
</template>

<script>
export default {
  name: "BannerRental"
}
</script>

<style lang="less">
@import '../../assets/styles/main.less';
@import '../../assets/styles/font.less';

#banner-rental {
  height: 250px;
  background-color: @myRed;
  width: 80%;
  position: absolute;
  margin-top: -4%;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .container-title {
    padding: 0px 50px;
    .rental-title {
      letter-spacing: 2px;
      .set-font-bold(@size: 50px; @color: @myWhite);
    }
    .border-title {
      border: 2px solid @myWhite;
      width: 20%;
      margin-bottom: 20px;
    }
    .btn-info-rental {
      margin-top: 20px;
      border-radius: 0px;
      border: 1px solid @myWhite;
      .set-font(@size: 12px; @color: @myWhite);
    }
    .btn-info-rental:hover {
      background-color: @myWhite;
      .set-font(@size: 12px; @color: @myRed);
    }
    .btn-info-rental:focus {
      box-shadow: none;
    }
  }
  .container-img-rental {
    height: 250px;
    background: url(@/assets/img/noleggio.jpg) no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}


@media only screen and (max-width: 1500px) {
  #banner-rental {
    height: 250px;
    background-color: @myRed;
    position: absolute;
    margin-top: -7%;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .container-title {
      padding: 0px 50px;

      .rental-title {
        letter-spacing: 2px;
        .set-font-bold(@size: 40px; @color: @myWhite);
      }

      .border-title {
        border: 2px solid @myWhite;
        width: 20%;
        margin-bottom: 20px;
      }

      .btn-info-rental {
        margin-top: 20px;
        border-radius: 0px;
        border: 1px solid @myWhite;
        .set-font(@size: 12px; @color: @myWhite);
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  #banner-rental {
    height: 250px;
    background-color: @myRed;
    position: absolute;
    margin-top: -10%;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .container-title {
      padding: 0px 50px;

      .rental-title {
        letter-spacing: 2px;
        .set-font-bold(@size: 18px; @color: @myWhite);
      }

      .border-title {
        border: 2px solid @myWhite;
        width: 20%;
        margin-bottom: 20px;
      }

      .btn-info-rental {
        margin-top: 20px;
        border-radius: 0px;
        border: 1px solid @myWhite;
        .set-font(@size: 12px; @color: @myWhite);
      }
      .description{
        .set-font(@size: 12px);
      }
    }

    .container-img-rental {
      display: none;
    }
  }
}


@media only screen and (max-width: 900px) {
  #banner-rental {
    height: 250px;
    background-color: @myRed;
    position: absolute;
    margin-top: -20%;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .container-title {
      padding: 0px 50px;

      .rental-title {
        letter-spacing: 2px;
        .set-font-bold(@size: 18px; @color: @myWhite);
      }

      .border-title {
        border: 2px solid @myWhite;
        width: 20%;
        margin-bottom: 20px;
      }

      .btn-info-rental {
        margin-top: 20px;
        border-radius: 0px;
        border: 1px solid @myWhite;
        .set-font(@size: 12px; @color: @myWhite);
      }
      .description{
        .set-font(@size: 12px);
      }
    }

    .container-img-rental {
      display: none;
    }
  }
}
</style>
