<template>
  <b-container class="page">
    <b-row>

      <b-col class="container-box-elements" cols="12" md="6">
        <div class="main-title">
          <div class="main-title-2"></div>
          <div class="main-title-3">
            Negozio
          </div>
        </div>
        <div class="quote">
          <p>L'obbiettivo di tutto lo staff di Due Elle Sport è quello di mettere al servizio del cliente un pacchetto completo di esperienza, competenza e disponibilità.<br>
            Questo implica che il focus primario di tutti i nostri collaboratori non sia la mera vendita di prodotto, ma essere in grado di fornire un servizio che renda il cliente soddisfatto e fidelizzato. Come spesso diciamo " Una volta nostro cliente, per sempre nostro cliente"
          </p>
        </div>
      </b-col>
      <b-col class="container-box-elements" cols="12" md="6">
        <simple-svg
            :src="getImage('negozio')"
            width="100%"
            height="100%"
            custom-class-name="icon-gray"
        />
      </b-col>
    </b-row>

    <br>
    <div class="content-left">
      <h2>Prodotti</h2>
      <h1>Sci</h1>
      <p>Vasto assortimento di sci delle migliori marche ti aspettano per soddisfare le tue esigenze da sciatore a
        qualsiasi livello tecnico tu appartenga, sia che tu sia principiante sia agonista.<br>
        Affidati a noi per trovare lo sci perfetto per massimizzare la tua esperienza e divertimento sulle piste.</p>
    </div>

    <div class="content-right">
      <h2>Prodotti</h2>
      <h1>Snowboard</h1>
      <p>Ampia varietà di tavole da snowboard di marchi specializzati quali salomon, rossignol e Drake, ideali per chi
        vuole affrontare la prima curva o per i rider più esperti.</p>
    </div>

    <div class="content-left">
      <h2>Prodotti</h2>
      <h1>Accessori</h1>
      <p>L’equipaggiamento e la sicurezza per chi pratica sport invernali devono essere un punto focale per ogni
        appassionato della montagna.<br>
        L’incolumità ed il divertimento sono di fondamentale importanza per vivere un’esperienza appagante.<br>
        Da noi puoi trovare scarponi, caschi, maschere, protezioni, attacchi e tanto altro, per vivere al meglio le tue
        giornate sulle piste.
      </p>
    </div>

    <div class="content-right">
      <h2>Prodotti</h2>
      <h1>Abbigliamento</h1>
      <p>Affinché le tue giornate in montagna siano sicure e confortevoli, devi essere certo di avere in dotazione un
        abbigliamento adeguato.<br>
        Da noi trovi le migliori marche per uomo/donna/bambino e baby. Completi, giacche, pantaloni, secondi strati,
        intimo tecnico ed accessori.<br>
        Tutti i nostri capi sono realizzati con tessuti di alta qualità, termici ed impermeabili, in grado di garantire
        il massimo del comfort ed il top delle performance sulle piste e non solo.</p>
    </div>

    <div class="content-left">
      <h2>Prodotti</h2>
      <h1>Mondo ghiaccio</h1>
      <p>Pattinaggio artistico e hockey hanno grande rilievo all’interno del nostro negozio.<br>
        Oltre ai pattini da ghiaccio EDEA, ROCES, TEMPISH, CCM, BAUER troverai tutta l’attrezzatura e l’abbigliamento
        per eccellere al meglio in entrambi gli sport.</p>
    </div>

    <div class="content-right">
      <h2>Prodotti</h2>
      <h1>Tanto altro</h1>
      <p>Oltre a tutte questo, da Due Elle Sport trovi molto altro!<br>
        Dal trekking al tempo libero, dalla piscina al tennis ed al running.<br>
        Vieni a trovarci!
      </p>
    </div>
  </b-container>
</template>

<script>
export default {
  name: "negozio-page",
  methods: {
    getImage: function (icon) {
      return require('../assets/img/' + icon + '.svg')
    }
  }
}
</script>

<style lang="less">
@import '@/assets/styles/main.less';
@import '@/assets/styles/font.less';

#detail-page {
  margin: 10%;
}
</style>
