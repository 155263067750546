<template>
  <div class="container-welcome" :style="{height: height + 'px'}">
    <carousel :style="{height: height + 'px'}" :perPage="1" :autoplayTimeout="7000" autoplay
              :autoplay-hover-pause="false"
              :scrollPerPage="1"
              v-bind:loop="true"
              :navigationEnabled="false" :paginationEnabled="false">
      <slide :style="{height: height + 'px', width: width + 'px'}" id="welcome">
        <div class="main-text home-title">
          <div class="home-title-2"></div>
          <div class="home-title-4">
            Dueelle Sport
          </div>
          <div class="home-title-3">
            Il Tuo Negozio
          </div>
          <div class="home-title-5">
            A Varese
          </div>
        </div>
      </slide>
      <slide :style="{height: height + 'px', width: width + 'px'}" id="slider2">
        <div class="second-text home-title">
          <div class="home-title-2"></div>
          <div class="home-title-4">
            Dueelle Sport
          </div>
          <div class="home-title-3">
            Il Tuo Negozio
          </div>
        </div>
      </slide>
    </carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';

export default {
  name: "HomeView",
  components: {
    Carousel,
    Slide
  },
  props: {
    height: {
      type: Number
    },
    width: {
      type: Number
    }
  }
}
</script>

<style lang="less">
@import '../../assets/styles/main.less';
@import '../../assets/styles/font.less';

.main-text {
  position: absolute;
  top: 40%;
  left: 20%;
  transform: translate(-50%, -50%);
  padding: 20px; /* Puoi regolare il padding a tuo piacimento */
}
#welcome{
  background: url(@/assets/img/bg_home_1.jpg) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
  width: 100%;

  .container-welcome-info {
    position: absolute;
    left: 100px;
    background-color: @myWhite;
    border-radius: 10px;
    width: 350px;
    top: 35%;
    height: 400px;
    .title-welcome {
      letter-spacing: 1px;
      border-bottom: 1px solid @borderMenu;
      padding: 15px;
      .set-font-medium(@family: 'druk_wide'; @size: 20px; @color: @myTextColor);
    }
    .body-welcome {
      padding: 0px 15px;
      .el-news {
        display: flex;
        align-items: flex-start;
        border-bottom: 1px solid @borderMenu;
        padding: 10px 0;
        &.el-news-link {
          cursor: pointer;
        }
        .container-date {
          text-align: center;
          .date-info {
            .set-font-bold(@family: 'druk_wide'; @color: @myOrange; @size: 16px);
            &.day {
              height: 24px;
              .set-font-bold(@family: 'druk_wide'; @size: 24px;@color: @myOrange;);
            }
          }
        }
        .container-info-txt {
          margin-left: 10px;
          .title-info {
            .set-font-bold(@size: 13px; @color: @myTextColor);
          }
          .subTitle-info {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2;
            -webkit-box-orient: vertical;
            .set-font(@size: 10px; @color: @myGrayLight);
          }
        }
      }
    }
    .no-data {
      .set-font(@size: 14px; @color: @myGray);
      text-align: center;
    }
  }
}

#slider2 {
  background: url(@/assets/img/whoweare.jpg) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
  width: 100%;

  .second-text {
    position: absolute;
    top: 40%;
    right: -10%;
    transform: translate(90%, -50%);
    padding: 20px; /* Puoi regolare il padding a tuo piacimento */
  }
}
@media only screen and (min-width: 900px) {
  #welcome{
    .container-box-welcome {
      .container-welcome-info {
        display: none;
      }
    }
  }
  #slider2 {
    .container-box-welcome {
      .container-welcome-info {
        display: none;
      }
    }
  }
}
@media only screen and (max-width: 800px) {
  #welcome {
    background-attachment: scroll;
    background-repeat: no-repeat;
    background-position: center;
    .container-box-welcome {
      margin: auto;
      width: 100%;
      display: flex;
      justify-content: center;
      .container-welcome-info {
        width: 100%;
        margin: 50px 20px;
        left: 0px;
        position: relative;
      }
    }

  }
}
</style>
