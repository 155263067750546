<template>
  <div id="header-dueelle">

    <b-navbar id="navbar-top" toggleable type="light" variant="faded">

      <div class="d-flex align-items-center container-items-header noPadding">
        <b-col sm="2" md="2" lg="2" cols="4" class="noPadding container-logo">
          <b-navbar-brand href="/">
            <img class="image-due-elle-box" src="../../assets/img/Logo.svg" alt="Logo dueelle sport varese"/>
          </b-navbar-brand>
        </b-col>
        <b-col sm="10" md="10" lg="10" cols="8" class="justify-content-end align-items-center noPadding">
          <div class="noPadding container-row-right d-flex flex-wrap">
            <b-col cols="12" class="noPadding container-top-right d-flex align-items-center justify-content-between">
              <div class="contact-generic d-flex contact">
                  <b-col v-for="el in Object.keys(contact)" :key="el" class="container-single-contact d-flex align-items-center justify-content-center">
                    <b-icon class="icon-social" v-if="el === 'email'" :icon="'envelope'" font-scale="1" @click="goToEmailPhone(contact[el], 'mailto')"></b-icon>
                    <b-icon class="icon-social" v-else :icon="el" font-scale="1" @click="goToEmailPhone(contact[el], 'tel')"></b-icon>
                    <span class="label-contact">{{contact[el]}}</span>
                  </b-col>
              </div>
              <div class="contact-social d-flex contact">
                <b-col v-for="el in Object.keys(social)" :key="el" class="container-single-contact d-flex align-items-center justify-content-center">
                  <b-icon class="icon-social" :icon="el" font-scale="1" @click="goTo(social[el])"></b-icon>
                </b-col>
              </div>
            </b-col>

            <b-col cols="12" class="noPadding container-bottom-right d-flex align-items-center justify-content-end">
              <b-navbar-nav class="menu-header-large" v-for="(item, key) in menuItems" :key="key">
                <b-nav-item :href="item.route" class="item-navbar">{{item.title}}</b-nav-item>
              </b-navbar-nav>
              <b-navbar-toggle target="navbar-toggle-collapse" class="container-icon-menu">
                <template #default="{ expanded }">
                  <b-icon v-if="expanded" icon="chevron-bar-up"></b-icon>
                  <b-icon v-else icon="list-nested"></b-icon>
                </template>
              </b-navbar-toggle>
            </b-col>
          </div>
        </b-col>
      </div>


      <b-collapse id="navbar-toggle-collapse" is-nav>
        <b-navbar-nav class="ml-auto" v-for="(item, key) in menuItems" :key="key">
          <b-nav-item :href="item.route">{{item.title}}</b-nav-item>
        </b-navbar-nav>
      </b-collapse>

    </b-navbar>

  </div>
</template>

<script>
import utility from "@/mixins/utility";

export default {
  name: "HeaderDueElle",
  props: {
    width: {
      type: [ String, Number ]
    }
  },
  mixins: [utility],
  data () {
    return {
      menuItems: [
        {
          title: 'Home',
          route: '#'
        },
        {
          title: 'Chi siamo',
          route: '#about-us'
        },
        {
          title: 'Cosa offriamo',
          route: '#why-choose-us'
        },
        {
          title: 'Le nostre offerte',
          route: '#our-proposals'
        }
      ]
    }
  },
  mounted () {
  },
  methods: {
    goTo: function (link) {
      window.open(link)
    },
    goToEmailPhone: function (link, type) {
      window.location.href = type + ':' + link;
    },
  }
}
</script>

<style lang="less">
@import '../../assets/styles/main.less';
@import '../../assets/styles/font.less';

#header-dueelle {

  nav {
    padding: 0px 0px;
    height: 100px;
    &#navbar-top {
      background-color: @myWhite;
    }

    .container-items-header {
      width: 100%;
      height: 100px;

      .container-logo {
        background-color: @primary;
        height: 100%;
        .navbar-brand {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100px;
          .image-due-elle-box {
            height: 80px;
          }
        }
      }

      .container-row-right {
        height: 100px;
        .container-top-right {
          width: 100px;
          height: 40%;
          background-color: @secondary;
          color: @myWhite;
          .contact {
            height: 100%;
          }
          .contact-generic {
            .label-contact {
              margin-left: 10px;
              margin-top: 3px;
              .set-font(@size: 12px; @color: @myWhite;)
            }
          }
          .contact-social {

          }
          .container-single-contact {
            border-right: 1px solid @myWhite;
          }
          .container-single-contact:last-child {
            border-right: 0px;
          }
        }
        .container-bottom-right {
          height: 60%;
          .menu-header-large {
            flex-direction: row;
            .item-navbar {
              margin: 0 10px;
            }
          }
          .container-icon-menu {
            display: none;
            /* height: 100%;
            width: 80px;
            background-color: @primary;
            border: 0px;
            border-radius: 0px;
            .b-icon {
              color: @myWhite;
              font-size: 30px;
            } */
          }
        }
      }
    }

    #navbar-toggle-collapse {
      background-color: @myWhite;
      padding: 10px 20px;
      z-index: 100;
      .navbar-nav {
        .nav-item {
          .nav-link {
            color: @myBlack;
          }
        }
      }
    }

  }
}
@media only screen and (max-width: 1028px) {
  #header-dueelle {
    nav {
      .container-items-header {
        width: 100%;
        height: 100px;

        .container-logo {
          .navbar-brand {
            .image-due-elle-box {
              height: 50px;
            }
          }
        }
        .container-row-right {
          height: 100px;
          .container-top-right {
            width: 100px;
            height: 40%;
            background-color: @secondary;
            color: @myWhite;
            .contact {
              height: 100%;
            }
            .contact-generic {
              .label-contact {
                display: none;
              }
            }
            .contact-social {

            }
            .container-single-contact {
              border-right: 1px solid @myWhite;
            }
            .container-single-contact:last-child {
              border-right: 0px;
            }
          }
          .container-bottom-right {
            .menu-header-large {
              display: none;
            }
            .container-icon-menu {
              display: block;
              height: 100%;
              width: 80px;
              background-color: @primary;
              border: 0px;
              border-radius: 0px;
              .b-icon {
                color: @myWhite;
                font-size: 30px;
              }
            }
          }
        }
      }

      #navbar-toggle-collapse {
        background-color: @myWhite;
        .navbar-nav {
          .nav-item {
            .nav-link {
              color: @myBlack;
            }
          }
        }
      }

    }
  }
}
</style>
