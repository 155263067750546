<template>
  <div id="app">
    <header-due-elle :width="width"></header-due-elle>
    <router-view :height="height" :width="width"/>
    <footer-due-elle></footer-due-elle>
  </div>
</template>

<script>
import HeaderDueElle from "@/components/global/HeaderDueElle.vue";
import FooterDueElle from "@/components/global/FooterDueElle.vue";

export default {
  name: 'App',
  components: {
    FooterDueElle,
    HeaderDueElle
  },
  data: function () {
    return {
      height: window.innerHeight,
      width: window.innerWidth
    }
  },
  mounted() {
    window.addEventListener('resize', () => {
      this.setHeight()
    })
  },
  methods: {
    setHeight: function () {
      this.height = window.innerHeight
      this.width = window.innerWidth
    }
  },
  beforeDestroy: function () {
    window.removeEventListener('resize', () => {
    })
  }
}
</script>

<style lang="less">
@import '@/assets/styles/main.less';
@import '@/assets/styles/font.less';

.quote {
  position: relative;
  padding-top: 40px;
  padding-right: 30px;
}

.quote::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 120px;
  height: 120px;
  opacity: 0.6;
  background-image: url(@/assets/img/quote.svg);
  background-size: 100px;
  background-repeat: no-repeat;
  background-position: top right;
}

.main-title {
  position: relative;
  border-left: 8px solid @primary;
  border-top: 8px solid @primary;
  border-bottom: 8px solid @primary;
  height: 130px;
  width: 200px;
}

.main-title::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  height: 5px;
  border-right: 8px solid @primary;
}

.main-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  height: 5px;
  border-right: 8px solid @primary;
}

.main-title-2 {
  border-left: 2px solid #39BCEA;
  border-bottom: 2px solid #39BCEA;
  width: 30px;
  height: 30px;
  position: absolute;
  left: 20px;
  bottom: 10px;
}

.main-title-3 {
  text-transform: capitalize;
  .set-font-bold(@color: @myBlack; @size: 50px);
  position: absolute;
  top: 50%;
  left: 80%;
  width: 300px;
  transform: translate(-50%, -50%);
  margin-left: 40px;
}

.home-title {
  position: relative;
  border-left: 8px solid @primary;
  border-top: 8px solid @primary;
  border-bottom: 8px solid @primary;
  height: 250px;
  width: 130px;
}

.home-title::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  height: 5px;
  border-right: 8px solid @primary;
}

.home-title::after {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  height: 5px;
  border-right: 8px solid @primary;
}

.home-title-2 {
  border-left: 2px solid #39BCEA;
  border-bottom: 2px solid #39BCEA;
  width: 30px;
  height: 30px;
  position: absolute;
  left: 20px;
  bottom: 10px;
}

.home-title-3 {
  text-transform: capitalize;
  .set-font-bold(@color: @myWhite; @size: 60px);
  position: absolute;
  top: 50%;
  left: 150%;
  width: 350px;
  transform: translate(-50%, -50%);
  margin-left: 40px;
}

.home-title-4 {
  text-transform: capitalize;
  .set-font-bold(@color: @myWhite; @size: 20px);
  position: absolute;
  top: 18%;
  left: 120%;
  width: 300px;
  transform: translate(-50%, -50%);
  margin-left: 40px;
}

.home-title-5 {
  text-transform: capitalize;
  .set-font-bold(@color: @myWhite; @size: 40px);
  position: absolute;
  top: 83%;
  left: 120%;
  width: 300px;
  transform: translate(-50%, -50%);
  margin-left: 40px;
}

h1 {
  position: relative;
  display: inline-block;
  text-transform: capitalize;
  .set-font-bold(@color: @myBlack; @size: 50px);
}

h1::before {
  content: '';
  display: block;
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 80%;
  height: 4px;
  background-color: @borderTitle;
  transform-origin: 0 0;
  transform: scaleX(0.7);
}

h2 {
  .set-font-bold(@color: @subtitle; @size: 15px) !important;
  text-transform: uppercase;
}

p {
  margin-top: 1rem;
  .set-font(@color: @text;);
}

.content-left {
  text-align: left;
  margin-bottom: 80px;
}

.content-right {
  text-align: right;
  margin-bottom: 80px;

  h1::before {
    right: 0;
    left: initial;
    transform: scaleX(1);
  }
}

.page {
  margin-top: 100px;
}

.icon-gray {
  position: absolute;
  bottom: 0px;
  right: 0px;
  fill: @image;
  stroke: @image;
}

div {
  .set-font();
}
</style>
