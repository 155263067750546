<template>
  <b-container class="page">
    <b-row>

      <b-col class="container-box-elements" cols="12" md="6">
        <div class="main-title">
          <div class="main-title-2"></div>
          <div class="main-title-3">
            Noleggio
          </div>
        </div>
        <div class="quote">
          <p>Il nostro noleggio viene costantemente aggiornato, garantendo al cliente il perfetto mix tra comodità,
            performance e divertimento a seconda del livello tecnico del cliente.<br>
            Tutta l’attrezzatura quali borse, scarponi, bastoni e caschi vengono puliti, lavati ed igienizzati per poter
            essere consegnati al top della condizione.<br>
            Particolarmente apprezzato la possibilità di noleggio attrezzatura nuova con diritto di riscatto a fine
            stagione o formule ad hoc pattuite preventivamente con il cliente.<br>
            Il nostro parco noleggio risulta essere uno dei più forniti di tutta la provincia, coprendo una platea di
            clientela vasta ed eterogenea a partire dai 3 anni.</p>
        </div>
      </b-col>

      <b-col class="container-box-elements" cols="12" md="6">
        <simple-svg
            :src="getImage('noleggio')"
            width="100%"
            height="100%"
            custom-class-name="icon-gray"
        />
      </b-col>
    </b-row>

    <br>
  </b-container>
</template>

<script>
export default {
  name: "noleggio-page",
  methods: {
    getImage: function (icon) {
      return require('../assets/img/' + icon + '.svg')
    }
  }
}
</script>

<style lang="less">
@import '@/assets/styles/main.less';
@import '@/assets/styles/font.less';

#detail-page {
  margin: 10%;
}
</style>
