<template>
  <div id="why-choose-us" :style="{height: height + 'px'}">
    <div class="container-why-choose">
      <b-col class="container-header-box-why">
        <div class="title-box">SERVIZI
          <div class="title-box-overlap">COSA OFFRIAMO</div>
          <div class="border-title"></div>
        </div>
        <div class="sub-title-box">Consulenza ed esperienza al servizio del cliente per seguirlo e consigliarlo prima e
          dopo l’acquisto.
        </div>
      </b-col>
      <b-col class="container-mini-box-why">
        <div v-for="(element, key) in sports" :key="key" class="box-mini">
          <div class="title-box">{{ element.title }}</div>
          <simple-svg
              :src="getImage(element.icon)"
              width="90px"
              height="90px"
              custom-class-name="image-box"
          />
          <div class="description-box">{{ element.description }}</div>
        </div>
      </b-col>
    </div>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "WhyChooseUs",
  components: {},
  props: {
    height: {
      type: Number
    },
    width: {
      type: Number
    }
  },
  data() {
    return {
      sports: [
        {
          icon: 'sci',
          title: 'SCI',
          link: 'sci',
          description: 'Le migliori marche al miglior prezzo.'
        },
        {
          icon: 'snow',
          title: 'SNOWBOARD',
          link: 'snowboard',
          description: 'Tavole-scarponi-attacchi per soddisfare i tuoi gusti.'
        },
        {
          icon: 'pattini',
          title: 'PATTINI',
          link: 'pattini',
          description: 'Vivi il mondo del ghiaccio al massimo.'
        },
        {
          icon: 'accessori',
          title: 'ACCESSORIES',
          link: 'accessories',
          description: 'Il massimo del comfort per ogni evenienza.'
        }
      ]
    }
  },
  methods: {
    getImage: function (icon) {
      return require('../../assets/img/' + icon + '.svg')
    },
    goTo: function (link) {
      router.push({name: link})
      window.scrollTo(0, 0)
    }
  }
}
</script>

<style lang="less">
@import '../../assets/styles/main.less';
@import '../../assets/styles/font.less';

#why-choose-us {
  background-color: @myBlack;

  .container-header-box-why {
    text-align: center;
    padding-top: 150px;

    .title-box {
      .set-font-bold(@size: 80px; @color: @myWhite);
      display: inline-block;
      height: 90px;
      position: relative;
      margin: 0 auto;
      text-align: center;
      -webkit-text-stroke: 1px @subtitle;
      color: transparent;
      letter-spacing: 15px;

      .title-box-overlap {
        position: absolute;
        top: 20px;
        left: 0px;
        width: 100%;
        .set-font-bold(@size: 50px; @color: @myWhite);
        -webkit-text-stroke: 0px @subtitle;
        letter-spacing: 1px;
      }

      .border-title {
        top: 95%;
        left: 40%;
        width: 20%;
        position: absolute;
        border: 2px solid @borderTitle;
      }
    }

    .sub-title-box {
      margin-top: 20px;
    }
  }

  .container-mini-box-why {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    padding: 50px 150px;

    .box-mini {
      width: 300px;
      height: 300px;
      background-color: @boxGray;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 10px;
      padding: 15px;
      cursor: pointer;

      .title-box {
        .set-font-bold(@size: 18px; @color: @myWhite);
      }

      .image-box {
        fill: @myWhite;
        margin: 20px;
      }
    }
  }
}

@media only screen and (max-width: 1600px) {
  #why-choose-us {
    height: 1100px !important;
  }
}

@media only screen and (max-width: 1200px) {
  #why-choose-us {
    .container-header-box-why {
      padding-top: 50px;
      padding-bottom: 50px;

      .title-box {
        .set-font-bold(@size: 35px; @color: @myWhite);
        display: inline-block;
        height: 90px;
        position: relative;
        margin: 0 auto;
        text-align: center;
        -webkit-text-stroke: 1px @subtitle;
        color: transparent;
        letter-spacing: 12px;

        .title-box-overlap {
          position: absolute;
          top: 5px;
          left: 0px;
          width: 100%;
          .set-font-bold(@size: 25px; @color: @myWhite);
          -webkit-text-stroke: 0px @subtitle;
          letter-spacing: 1px;
        }

        .border-title {
          top: 40%;
          left: 40%;
          width: 20%;
          position: absolute;
          border: 2px solid @borderTitle;
        }
      }

      .sub-title-box {
        margin-top: 10px;
      }
    }

    .container-mini-box-why {
      padding: 0px;
    }
  }
}

@media only screen and (max-width: 800px) {
  #why-choose-us {
    height: 1800px !important;

    .container-header-box-why {
      padding-top: 50px;
      padding-bottom: 50px;

      .title-box {
        .set-font-bold(@size: 35px; @color: @myWhite);
        display: inline-block;
        height: 90px;
        position: relative;
        margin: 0 auto;
        text-align: center;
        -webkit-text-stroke: 1px @subtitle;
        color: transparent;
        letter-spacing: 12px;

        .title-box-overlap {
          position: absolute;
          top: 5px;
          left: 0px;
          width: 100%;
          .set-font-bold(@size: 25px; @color: @myWhite);
          -webkit-text-stroke: 0px @subtitle;
          letter-spacing: 1px;
        }

        .border-title {
          top: 40%;
          left: 40%;
          width: 20%;
          position: absolute;
          border: 2px solid @borderTitle;
        }
      }

      .sub-title-box {
        margin-top: 10px;
      }
    }

    .container-mini-box-why {
      padding: 0px;


      .box-mini {

      }
    }
  }
}
</style>
