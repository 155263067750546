const utility = {
    data() {
        return {
            social: {
                'facebook': "https://www.facebook.com/dueelle.sport",
                'instagram': "https://www.instagram.com/dueelle_sport/?hl=it"
            },
            contact: {
                'email': "sport2013@virgilio.it",
                'phone': "0332265806"
            }
        }
    },
}
export default utility
