<template>
  <div id="about-us" :style="{height: height + 'px'}">
    <b-col cols="12" sm="6" md="6" lg="6">
     <div class="container-info-about">
       <div class="sub-title">SU DI NOI</div>
       <div class="title-about">Chi Siamo</div>
       <div class="border-title"></div>
       <div class="description">Dal 1986 due Elle sport offre con competenza e professionalità un servizio completo per gli amanti della montagna, sia per quanto riguarda l’acquisto dell’attrezzatura e l’abbigliamento, ma anche la manutenzione degli sci/snowboard ed il noleggio degli stessi.</div>
     </div>
    </b-col>
    <b-col cols="0" sm="6" md="6" lg="6" class="container-img-about-us" :style="{height: height + 'px'}">
    </b-col>
  </div>
</template>

<script>
export default {
  name: "AboutUs",
  components: {},
  props: {
    height: {
      type: Number
    },
    width: {
      type: Number
    }
  }
}
</script>

<style lang="less">
@import '../../assets/styles/main.less';
@import '../../assets/styles/font.less';

#about-us {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .container-info-about {
    text-align: start;
    padding-left: 40%;
    .sub-title {
      .set-font-bold(@color: @subtitle; @size: 15px);
    }
    .title-about {
      height: 60px;
      .set-font-bold(@color: @myBlack; @size: 50px);
    }
    .border-title {
      border: 2px solid @borderTitle;
      width: 30%;
      margin-bottom: 20px;
    }
    .description {
      .set-font(@color: @myBlack; @size: 20px);
    }
  }

  .container-img-about-us {
    background: url(@/assets/img/whoweare.jpg) no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
  }
}

@media only screen and (max-width: 900px) {
  #about-us {
    height: 1300px !important;
    .container-info-about {
      text-align: start;
      padding-left: 0%;

      .sub-title {
        padding-top: 20px;
        .set-font-bold(@color: @subtitle; @size: 15px);
      }

      .title-about {
        height: 100px;
        .set-font-bold(@color: @myBlack; @size: 50px);
      }

      .border-title {
        border: none;
      }

      .description {
        padding-bottom: 20px;
        .set-font(@color: @myBlack; @size: 15px);
      }
    }
  }
}
</style>
