<template>
  <footer id="footer-due-elle">
    <b-col class="container-info-due-elle">
      <img class="image-due-elle-box" src="../../assets/img/Logo.svg" alt="Logo dueelle sport varese"/>
      <div class="container-info">
        <div class="container-info-footer">
          <b-icon class="icon" :icon="'geo-alt-fill'" font-scale="1" ></b-icon>
        </div>
        <div class="container-info-footer"><a href="https://maps.app.goo.gl/cZMqjdSdY6CdCd7m6" target="_blank"> Via San Michele del Carso, 100, 21100 Varese VA</a></div>
      </div>
      <div class="container-info">
        <div class="container-info-footer">
          <b-icon class="icon" :icon="'phone'" font-scale="1" ></b-icon>
        </div>
        <div class="container-info-footer">{{contact['phone']}}</div>
      </div>
      <div class="container-info">
        <div class="container-info-footer" v-for="(el, key) in Object.keys(social)" :key="key">
          <b-icon class="icon" :icon="el" font-scale="2" ></b-icon>
        </div>
      </div>
    </b-col>
  </footer>
</template>

<script>
import utility from "@/mixins/utility";

export default {
  name: "FooterDueElle",
  mixins: [utility]
}
</script>

<style lang="less">
@import '../../assets/styles/main.less';
@import '../../assets/styles/font.less';

#footer-due-elle {
  height: 500px;
  background-color: @myBlack;
  .container-info-due-elle {
    height: 90%;
    padding-left: 20%;
    padding-top: 80px;
    .image-due-elle-box {
      height: 100px;
    }
    .container-info {
      display: flex;
      width: 250px;
      margin-top: 20px;
      .container-info-footer {
        padding: 5px;
        .icon {
          color: @myRed;
        }
      }
    }
  }
  .container-copyright {
    text-align: center;
  }
}
</style>
